import React, { useMemo } from 'react';
import { ISwiperToolbarProps } from './types';
import Icon from '@/components/base/icon';
import { functions } from '@wap-client/core';

const SwiperToolbar: React.FC<ISwiperToolbarProps> = ({
  sliderId,
  pagination = true,
  navigation = true,
  className,
}) => {
  if (!navigation && !pagination) {
    return null;
  }

  const selectors = useMemo(() => {
    if (!sliderId) {
      return null;
    }

    return {
      toolbar: `toolbar-${sliderId}`,
      navigation: {
        prev: `prev-${sliderId}`,
        next: `next-${sliderId}`,
      },
      pagination: `pagination-${sliderId}`,
    };
  }, [sliderId]);

  return (
    <div
      className={functions.classnames(
        'swiper-toolbar',
        className,
        selectors && selectors.toolbar
      )}
    >
      {navigation && (
        <>
          <div
            className={functions.classnames(
              'swiper-toolbar-prev',
              selectors && selectors.navigation.prev
            )}
          >
            <Icon name="icon-chev-left" />
          </div>
          <div
            className={functions.classnames(
              'swiper-toolbar-next',
              selectors && selectors.navigation.next
            )}
          >
            <Icon name="icon-chev-right" />
          </div>
        </>
      )}
      {pagination && (
        <div
          className={functions.classnames(
            'swiper-toolbar-pagination',
            selectors && selectors.pagination
          )}
        />
      )}
    </div>
  );
};

export default SwiperToolbar;
