import CustomButton from '@/components/widgets/custom-button';
import Anchor from '@/components/base/anchor';
import Image from '@/components/base/image';

import { functions, useApp } from '@wap-client/core';

import { UICategoryCard } from './types';

const CategoryCard: React.FC<UICategoryCard> = ({
  title,
  link,
  image,
  description,
  imageDescription,
  size = 'big',
}) => {
  const app = useApp();

  const renderBigCard = () => {
    return (
      <>
        <div className={functions.classnames('category-card-thumbnail')}>
          <Anchor {...link}>
            <Image
              className="category-card-thumbnail-image"
              {...image}
              alt={title}
            />
            <div className="category-card-thumbnail-desc">{description}</div>
          </Anchor>
        </div>

        <div className="category-card-more">
          <div className="category-card-more-text">{title}</div>

          <CustomButton
            anchor={link}
            className="category-card-more-button"
            round={20}
          >
            {app.settings.translations['discoverall']}
          </CustomButton>
        </div>
      </>
    );
  };

  const renderSmallCard = () => {
    return (
      <div className={functions.classnames('category-card-thumbnail')}>
        <div className="opacity-background"></div>
        <Image
          className="category-card-thumbnail-image"
          {...image}
          alt={title}
        />
        <div className="category-card-thumbnail-detail">
          {imageDescription ? (
            <Image className="image-desc" {...imageDescription} />
          ) : description ? (
            <h3>{description}</h3>
          ) : null}

          {link && (
            <CustomButton round={20} anchor={link}>
              {app.settings.translations['discoverall']}
            </CustomButton>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className={functions.classnames(
        'category-card',
        `category-card--${size}`
      )}
    >
      {size === 'big' ? renderBigCard() : renderSmallCard()}
    </div>
  );
};

export default CategoryCard;
